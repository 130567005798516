<script setup>

import { computed, ref, defineEmits, defineProps } from 'vue';
import { useUserActions } from '@user';
import { useLeadActions } from '@lead';
import { useProfileActions } from '@profile';
import { useSettingsActions } from '@settings';

const getProfileResource = useProfileActions('getProfileResource');

const getSales = useUserActions('getSales');
const getRetentions = useUserActions('getRetentions');

const {
    massUpdate,
    massDelete,
    archiving,
    getLeadResource,
} = useLeadActions();

const leads = getLeadResource();

 const props = defineProps({
     targets: {
         type: Array,
         default: () => [],
     }
 });

const emit = defineEmits(['performed', 'close', 'updated', 'drop']);


const profile = getProfileResource();

const sales = ref([]);
const retentions = ref([]);

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('statuses');

const statuses = computed(() => settings.statuses || []);

const saleSelects = ref([{
    sale: null,
    conversionStatus: null,
}]);
const retentionSelects = ref([{
    retention: null,
    retentionStatus: null,
}]);

const payloadLength = computed(() => {
    let length = 0;

    if (saleSelects.value.length === retentionSelects.value.length) {
        length = saleSelects.value.length;
    } else {
        length =
            saleSelects.value.length > retentionSelects.value.length
            ? saleSelects.value.length
            : retentionSelects.value.length;
    }

    return length;
});

const disableApply = computed(() => {
    return !saleSelects.value.some(select => select.sale || select.conversionStatus) &&
        !retentionSelects.value.some(select => select.retention || select.retentionStatus);
})

getSales().then(response => {
    sales.value = response.data;
});

const isAllowRitentionAssign = ref(true);
props.targets.forEach(id => {
    id = Number(id);
   const lead = leads.state.find(lead => lead.id === id);
   if (lead.is_ftd === 'No') {
       isAllowRitentionAssign.value = false;
       return;
   }
});

getRetentions().then(response => {
    retentions.value = response.data;
});

function update() {
    const payload = {
        data: []
    }

    for (let i = 0; i < payloadLength.value; i++) {
        const { sale: saleId, conversionStatus } = saleSelects.value[i] ? saleSelects.value[i] : {};
        const { retention: retentionId, retentionStatus } = retentionSelects.value[i] ? retentionSelects.value[i] : {};

        if (saleId || conversionStatus || retentionId || retentionStatus) {
            payload.data.push({
                ...(saleId ? { sale_id: saleId } : {}),
                ...(retentionId ? { retention_id: retentionId } : {}),
                ...(conversionStatus ? { conversion_status: conversionStatus } : {}),
                ...(retentionStatus ? { retention_status: retentionStatus } : {}),
            })
        }
    }

    const targetsLength = Math.floor(props.targets.length / payload.data.length);

    payload.data = payload.data.map((item, i) => {
        const start = targetsLength * i;
        const end = start + targetsLength;
        const trgts = i === payload.data.length - 1 ? props.targets.slice(start) : props.targets.slice(start, end);

        return {
            ...item,
            targets: trgts
        }
    });

    massUpdate(payload).then(() => {
        emit('updated');
        emit('close');
    });
}

function removeLeads() {
    massDelete(props.targets).then(() => {
        emit('updated');
        emit('close');
    });
}

function archiveLeads() {
    archiving.massArchive(props.targets).then(() => {
        emit('updated');
        emit('close');
    });
}

function addSelects(name) {
    name === 'sale'
        ? saleSelects.value.push({
            sale: null,
            conversionStatus: null,
        })
        : retentionSelects.value.push({
            retention: null,
            retentionStatus: null,
        });
}

function removeSelects(name) {
    name === 'sale'
        ? saleSelects.value.pop()
        : retentionSelects.value.pop();
}
</script>

<template>
    <form v-on:submit.prevent="update">
        <fieldset>
            <div class="select-group">
                <div
                    v-for="(row, i) in saleSelects"
                    v-bind:key="i"
                    class="row"
                >
                    <g-select v-if="profile.can('Lead SaleView')"
                          v-model="row.sale"
                          v-bind:options="sales"
                          :label="$t('lead.sale')"
                          option-text="name"
                          option-value="id"
                          v-bind:readonly="profile.cant('Lead SaleEdit')"/>

                    <g-select v-if="profile.can('Lead ConversionStatusView')"
                            v-model="row.conversionStatus"
                            v-bind:options="statuses.filter(item=> item.target === 'conversion')"
                            :label="$t('lead.conversion_status')"
                            option-text="name"
                            option-value="name"
                            v-bind:readonly="profile.cant('Lead ConversionStatusEdit')"/>

                    <g-button
                        v-if="i === saleSelects.length - 1"
                        class="add-selects"
                        v-on:click="addSelects('sale')"
                    />
                    <g-button
                        v-if="i === saleSelects.length - 1 && saleSelects.length > 1"
                        class="remove-selects remove-btn"
                        v-on:click="removeSelects('sale')"
                    >
                        <g-symbol name="delete" width="20" height="20"/>
                    </g-button>
                </div>

                <div
                    v-for="(row, i) in retentionSelects"
                    v-bind:key="i"
                    class="row"
                >
                    <g-select v-if="profile.can('Lead RetentionView')"
                          v-model="row.retention"
                          v-bind:options="retentions"
                          :label="$t('lead.retention')"
                          option-text="name"
                          option-value="id"
                          v-bind:readonly="profile.cant('Lead RetentionEdit') || !isAllowRitentionAssign"/>

                    <g-select v-if="profile.can('Lead RetentionStatusView')"
                            v-model="row.retentionStatus"
                            v-bind:options="statuses.filter(item=> item.target === 'retention')"
                            :label="$t('lead.retention_status')"
                            option-text="name"
                            option-value="name"
                            v-bind:readonly="profile.cant('Lead RetentionStatusEdit')"/>

                    <g-button
                        v-if="i === retentionSelects.length - 1"
                        class="add-selects"
                        v-on:click="addSelects('retention')"
                        v-bind:disabled="profile.cant('Lead RetentionEdit') || 
                            !isAllowRitentionAssign ||
                            profile.cant('Lead RetentionStatusEdit')
                        "
                    />
                    <g-button
                        v-if="i === retentionSelects.length - 1 && retentionSelects.length > 1"
                        class="remove-selects remove-btn"
                        v-on:click="removeSelects('retention')"
                    >
                        <g-symbol name="delete" width="20" height="20"/>
                    </g-button>                   
                </div>
            </div>


            <g-button class="apply-btn"
                      type="submit"
                      :disabled="disableApply">
                <g-symbol name="check" width="20" height="20"/>
                {{ $t('base.apply') }}
            </g-button>

            <g-button v-if="profile.can('Lead Archive')"
                      class="archive-btn"
                      type="button"
                      v-on:click="archiveLeads"
                      v-bind:data-tooltip="$t('base.archive', 'Archive')">
                <g-symbol name="archive" width="20" height="20"/>
            </g-button>
            <g-button v-if="profile.can('Lead Remove')"
                      class="remove-btn"
                      type="button"
                      v-on:click="removeLeads"
                      v-bind:data-tooltip="$t('base.remove', 'Remove')">
                <g-symbol name="delete" width="20" height="20"/>
            </g-button>
        </fieldset>
    </form>
</template>

<style lang="scss" scoped>
.select-group {
    display: grid;
    row-gap: 10px;

    > .row {
        display: grid;
        grid-template-columns: 1fr 1fr var(--input-height, $input-height) var(--input-height, $input-height);
        align-items: flex-end;
        column-gap: 10px;
    }

    .add-selects {
        position: relative;
        background-color: transparent;
        border: 1px solid var(--label-color, $label-color);

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: var(--label-color, $label-color);
            width: calc(var(--input-height, $input-height) / 2);
            height: 1px;
        }

        &::after {
            transform: translate(-50%, -50%) rotate(90deg);
        }

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &:hover {
            &:not(:disabled) {
                color: var(--title-color, $title-color);
                fill: var(--btn-primary-color, #ffffff);
                border-color: var(--primary, #0a45af);
                background-color: var(--primary, #0a45af);
            }
        }
    }

    .remove-selects {
        &::before,
        &::after {
            display: none;
        }
    }
}

fieldset {
    gap: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    min-width: 800px;
}

.g-button {
    color: black;
    font-weight: bold;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.apply-btn {
        width: 110px;
        flex-shrink: 0;
        background-color: var(--success, $success);
        margin-left: auto;

        &:hover {
            color: white;
            fill: white;
            background-color: var(--success-hover, $success-hover);
        }
    }

    &.activate-btn {
        background-color: transparent;
        fill: var(--success, $success);
        color: var(--title-color, $title-color);
        border: 1px solid var(--success-hover, $success-hover);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--success, $success);
        }
    }

    &.remove-btn,
    &.archive-btn {
        flex-shrink: 0;
        font-size: 0 !important;
        width: var(--btn-height);
        position: relative;

        &:hover {
            &::after {
                content: attr(data-tooltip);
                position: absolute;
                z-index: 2;
                left: 50%;
                bottom: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                transform: translateX(-50%);
                padding: var(--tooltip-paddings, $tooltip-paddings);
                font-size: var(--tooltip-font-size, $tooltip-font-size);
                border-radius: var(--tooltip-rounding, $tooltip-rounding);
                color: var(--tooltip-text-color, $tooltip-text-color);
                background-color: var(--danger, $danger);
            }

            &::before {
                content: '';
                position: absolute;
                z-index: 3;
                left: 50%;
                bottom: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                border: var(--tooltip-arrow-size, $tooltip-arrow-size) solid transparent;
                transform: translateX(-50%);
                border-top-color: var(--danger, $danger);
            }
        }
    }

    &.archive-btn {
        background-color: transparent;
        fill: var(--danger-hover, $danger-hover);
        color: var(--title-color, $title-color);
        border: 1px solid var(--danger-hover, $danger-hover);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--danger, $danger);
        }
    }

    &.remove-btn {
        color: black;
        background-color: var(--danger, $danger);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--danger-hover, $danger-hover);
        }
    }
}

.close-btn {
    top: 20px;
    right: 10px;
    position: absolute;
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);
}

// @media (min-width: 725px) {
// }

@media (max-width: 1024px) {
    fieldset {
        gap: 6px 2px;
        flex-wrap: wrap;
        min-width: 600px;
    }

    .select-group {
        width: 100%;
    }

    .g-button {
        flex-grow: 1;
    }
}

@media (max-width: 767px) {
    fieldset {
        min-width: 0;
    }

    .select-group {
        .g-select {
            min-width: 200px;
        }

        > .row {
            grid-template-columns: 1fr;
            row-gap: 4px;
        }
    }
}

</style>

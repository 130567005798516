<template>
    <div class="archive environment">
        <indexbar v-on:perpage="changePerPage">
            <g-search-input :label="$t('base.search')" v-on:find="find" v-on:drop="archive.load()"/>
            <date-filter/>
        </indexbar>

        <div class="index">
            <div class="row header">
                <div class="cell">
                    <div class="check-all">
                        <g-symbol
                            name="check"
                            width="18"
                            height="18"
                            v-on:click="checkAll"/>
                    </div>
                </div>

                <div class="title cell id" v-bind:class="[{sortable: archive.sortBy === 'id'}, archive.sortDir]">
                    <g-flex align="center" gap="2" v-on:click="sortBy('id')">
                        ID
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <div class="title cell">
                    <div class="phone">{{ $t('lead.phone', 'Phone') }}</div>
                </div>
                <div v-for="item in columns"
                     v-bind:key="item.key"
                     class="title cell"
                     v-bind:class="[item.key, {sortable: archive.sortBy === item.key}, archive.sortDir]">
                    <g-flex v-if="item.sortable"
                            align="center"
                            justify="center"
                            gap="2"
                            v-bind:class="item.key"
                            v-on:click="sortBy(item.key)">
                        {{ $t(`lead.${item.name}`) }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                    <div v-else v-bind:class="item.key">{{ $t(`lead.${item.name}`) }}</div>
                </div>
            </div>
            <g-preloader-content :is-loading="archive.isLoading"/>
            <div class="row"
                 v-for="(archive, index) in archive.state"
                 v-bind:key="`archive-${archive.id}`"
                 v-bind:class="[{checked: targets.includes(archive.id.toString())}, {'open': $route.params.id === archive.id.toString()}]">
                <label class="checkbox cell">
                    <g-flex v-if="profile.can('Archive LabelView')" class="bookmark" direction="column">
                        <span v-if="!!archive.label" v-for="bookmark in archive.label.split(',')"
                              v-bind:key="`${bookmark}-${archive.id}`"
                              v-bind:class="bookmark"></span>
                    </g-flex>
                    <g-checkbox v-model="targets"
                                v-bind:value="archive.id.toString()"
                                v-on:click.stop="handleCheckboxClick($event, archive, index)" class="black"/>
                </label>
                <div class="cell id" v-bind:class="{sortable: archive.sortBy === 'id'}">
                    {{ archive.id }}
                </div>

                <div class="cell">
                    <g-copy v-if="profile.can('Archive ContactsView')" class="phone" v-bind:text="archive.phone"/>
                    <template v-else>
                        {{
                            archive.phone.replace(/^\+?(\d{3})(\d+)(\d{3})$/, (match, p1, p2, p3) => `+${p1}${p2.replace(/./g, '*')}${p3}`)
                        }}
                    </template>
                </div>

                <div v-for="column in columns"
                     v-bind:key="column.key"
                     class="cell"
                     v-bind:class="[column.key, {sortable: archive.sortBy === column.key}]">

                    <fieldset v-if="column.key === 'commentDate' && !!archive[column.name]">
                        <legend>{{ archive[column.name].created_at }}</legend>
                        <div>{{ archive[column.name].body }}</div>
                    </fieldset>
                    <template v-else-if="column.key === 'campaigns' && !!archive[column.name]">
                        {{
                            profile.can('Archive RealCampaignView')
                                ? [...new Set(archive[column.name].map(item => item.name))].join(', ')
                                : [...new Set(archive[column.name].map(item => item.alias))].join(', ')
                        }}
                    </template>
                    <template v-else>
                        {{
                            Array.isArray(archive[column.name])
                                ? [...new Set(archive[column.name].map(item => item.name))].join(', ')
                                : archive[column.name]
                        }}
                    </template>
                </div>
                <div class="cell">
                    <button class="restore-btn with-tooltip"
                            v-bind:data-tooltip="$t('lead.restore_lead', 'Restore Lead')"
                            v-on:click="restoreLead(archive.id)">
                        <g-symbol name="restore" width="24" height="24"/>
                    </button>
                </div>
            </div>
            <div class="info-block" v-if="!archive.state.length && !archive.isLoading">{{
                    $t('base.nothing_found')
                }}
            </div>
        </div>

        <g-pagination v-model="archive.meta.current_page"
                      :class="[{'pagination-float-left': $route.params.id},{'pagination-float-right': STATE.IS_SHOW_SIDEBAR}]"
                      v-on:change="paginate"
                      v-bind:per-page="archive.meta.per_page"
                      v-bind:total="archive.meta.total"/>

        <mass-restore v-if="targets.length"
                      v-bind:targets="targets"
                      v-on:updated="reloadArchive"
                      v-on:drop="targets = []"/>
    </div>
</template>

<script>

import { computed, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useArchiveActions, useArchiveComponents } from '@archive';
import { useProfileActions } from '@profile';

import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';
import { STATE } from '@/Ship';

const {
    Indexbar,
    MassRestore,
    DateFilter
} = useArchiveComponents();

const getArchiveResource = useArchiveActions('getArchiveResource');
const getProfileResource = useProfileActions('getProfileResource');
const restoring = useArchiveActions('restoring');

export default {
    name: 'Environment',
    computed: {
        STATE() {
            return STATE;
        }
    },
    components: {
        DateFilter,
        Indexbar,
        MassRestore,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();

        const profile = getProfileResource();
        const archive = getArchiveResource();

        const targets = ref([]);
        let lastSelectedIndex = null;

        function find(value) {
            if (route.name === 'Archive') {
                router.push({ name: 'Archive', params: route.params });
            }

            const key = Object.keys(value).pop();

            if ((key === 'id' && value[key].length) || value[key].length > 2) {
                archive.find(value);
            }

        }

        function changePerPage(value) {
            archive.meta.per_page = value;
            archive.setPerPage(value);
            archive.setPage(1);
            archive.load();

            profile.state.archive.perPage = value;
            profile.saveState();
        }

        function paginate(page) {
            archive.setPage(page);
            archive.load();
        }

        function reloadArchive() {
            archive.load();
            targets.value = [];
        }

        function sortBy(field) {
            if (archive.sortBy === field) {
                profile.state.archive.sortDir = archive.sortDir === 'asc'
                    ? 'desc'
                    : 'asc';
            }
            profile.state.archive.sortBy = field;
            profile.saveState();

            archive.sort(
                profile.state.archive.sortBy,
                profile.state.archive.sortDir
            );
            archive.load();
        }

        const columns = computed(() => {
            const columns = archive.columns.filter(column => !column.allow);

            return columns;
        });

        watch(() => STATE.BRAND, () => {
            reloadArchive();
        });

        archive.onload.push(() => {
            const objectIds = archive.state.map((obj) => obj.id);
            targets.value = targets.value.filter((target) => objectIds.includes(parseInt(target)));
        });

        onMounted(() => {
            archive.sort(
                profile.state.archive.sortBy,
                profile.state.archive.sortDir
            );
            archive.setPerPage(profile.state.archive.perPage);
            archive.setIncludes(['affiliate', 'sale', 'retention', 'country', 'campaigns']);

            archive.load();

            preloaderAligner('.environment');
        });

        function checkAll() {
            if (targets.value.length < archive.state.length) {
                archive.state.forEach(archive => {
                    targets.value.push(archive.id.toString());
                });
            } else {
                targets.value = [];
            }
        }

        const handleCheckboxClick = (event, archive, index) => {
            if (event.shiftKey && lastSelectedIndex !== null) {
                const startIndex = Math.min(lastSelectedIndex, index);
                const endIndex = Math.max(lastSelectedIndex, index);

                const newTargets = archive.state
                .slice(startIndex, endIndex + 1)
                .map(archive => archive.id.toString());

                targets.value = [...new Set([...targets.value, ...newTargets])];
            } else {
                const archiveId = archive.id.toString();
                if (!targets.value.includes(archiveId)) {
                    targets.value.push(archiveId);
                } else {
                    const targetIndex = targets.value.indexOf(archiveId);
                    targets.value.splice(targetIndex, 1);
                }

                lastSelectedIndex = index;
            }
        };

        const restoreLead = (id) => {
            const index = archive.state.findIndex(item => item.id === id);
            restoring.restore(id).then(() => {
                archive.state.splice(index, 1);
            });
        };

        return {
            targets,
            archive,
            columns,
            profile,
            find,
            changePerPage,
            reloadArchive,
            paginate,
            sortBy,
            checkAll,
            handleCheckboxClick,
            restoreLead
        };
    }
};
</script>

<style lang="scss" scoped>
.environment {
    position: relative;
    background-color: var(--env-bg, $env-bg);
}

.index {
    min-width: 100%;
    padding: 0 10px;
}

.row {
    fill: var(--main-text-color, $main-text-color);

    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }

    &:not(.header) {
        cursor: pointer;

        &.checked {
            color: black;

            :deep(.g-symbol) {
                fill: var(--success, $success) !important;
                z-index: 3;
            }

            & > .cell {
                background-color: var(--success-hover, $success-hover);
            }

            & > .cell.sortable {
                background-color: var(--success, $success);
            }
        }

        & > .cell {
            border: 0 solid transparent;
            border-width: 1px 0;
        }

        &:hover > .cell,
        &.open > .cell {
            border-color: var(--success, $success);
        }
    }
}

.cell {
    padding: 5px;
    white-space: nowrap;
    position: relative;

    &.title {
        cursor: default;

        .g-symbol {
            transition: transform 0.5s;
        }
    }

    &.checkbox {
        font-size: 0;
        min-width: 46px;
        position: relative;
        background-color: transparent !important;

        :deep(.g-symbol) {
            z-index: 2;
            opacity: 0.99 !important;
            background-color: var(--env-bg, $env-bg) !important;
        }
    }

    &.check-all,
    &.id,
    &.is_ftd,
    &.is_test,
    &.email_confirmed,
    &.phone_confirmed,
    &.phone,
    &.language,
    &.created_at {
        padding: 10px 8px;
        text-align: center;
    }

    &:not(.title) {
        &.commentDate {
            width: 250px;
            height: 48px;
            padding: 2px 0 0;

            & > fieldset {
                padding: 0;
                pointer-events: none;

                & > legend {
                    margin-left: 6px;
                    padding: 0 2px;
                    font-size: 0.9rem;
                    color: var(--secondary-hover, $secondary-hover);
                }

                & > div {
                    width: 250px;
                    padding: 0 8px 4px 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:hover > fieldset {
                position: absolute;
                z-index: 2;
                bottom: 0;
                padding-top: 18px;
                font-size: 1rem;
                border: none;
                border-radius: 5px;
                background-color: var(--secondary, $secondary);

                & > legend {
                    top: 16px;
                    position: relative;
                    color: var(--tooltip-text-color, $tooltip-text-color);
                }

                & > div {
                    color: white;
                    white-space: normal;
                }
            }
        }
    }
}

.row:nth-child(-n+6) .cell:not(.title).commentDate:hover > fieldset {
    top: 0;
    bottom: auto;
}

.sortable {
    fill: var(--sorted-text-color, $sorted-text-color);
    color: var(--sorted-text-color, $sorted-text-color);
    background-color: var(--sorted-bg, $sorted-bg);

    &.title {
        font-weight: bold;

        &.desc .g-symbol {
            transform: rotate(180deg);
        }
    }
}

.check-all {
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        fill: var(--title-color, $title-color);
    }
}

.g-pagination {
    padding: 16px 10px;
    position: fixed;
    bottom: 0;
    text-align: center;
    margin: 0 auto;
    left: calc(50% - 150px);
    right: 50%;
    transition: left 500ms, right 500ms;
    width: 400px;
}

.pagination-float-left {
    left: calc(50% - 640px);
}

.pagination-float-right {
    right: calc(50% - 270px);
}

.info-block {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 18px;
    color: var(--main-text-color, #aabfcf);
    background-color: var(--env-bg, #191d2b);
}

.bookmark {
    position: absolute;
    z-index: 2;
    top: 8%;
    left: 0;
    height: 86%;
    overflow: hidden;
    border-radius: 5px;
    width: calc(100% + 9px);
    clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%);

    & > span {
        flex-grow: 1;
        height: 100%;

        &.silver {
            background-image: linear-gradient(65deg, #b0b0bf, #f8f8ff);
        }

        &.gold {
            background-image: linear-gradient(65deg, #efa700, #ffef40);
        }

        &.green {
            background-image: linear-gradient(65deg, #12a555, #52e595);
        }

        &.cyan {
            background-image: linear-gradient(65deg, #008fcf, #50f5ff);
        }

        &.red {
            background-image: linear-gradient(65deg, #b01223, #e04354);
        }

        &.purple {
            background-image: linear-gradient(65deg, #700099, #d030ff);
        }
    }
}

@media (min-width: 1025px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 1024px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px 5px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 984px) {
    .pagination-float-left {
        transform: translate(295px, 0);
    }
}

button,
.restore-btn {
    display: flex;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    fill: var(--main-text-color, $main-text-color);
}

button:hover::after,
.restore-btn:hover::after {
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
}

button:hover::before,
.restore-btn:hover::before {
    transform: translateX(-50%);
    left: 50%;
    top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
}

button:last-child:hover::after {
    left: -35%;
}

.restore-btn:hover {
    fill: var(--success-hover, $success-hover);
}

.restore-btn:hover::after {
    background-color: var(--success, $success);
}

.restore-btn:hover::before {
    border-bottom-color: var(--success, $success);
}


</style>
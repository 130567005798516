<script setup>
import { useLeadActions } from '@lead';
import { useProfileActions } from '@profile';
import { computed, ref } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';

const getLeadResource = useLeadActions('getLeadResource');
const getProfileResource = useProfileActions('getProfileResource');
const kyc = useProfileActions('kycActions');
const uploadDocument = useProfileActions('uploadDocument');

const lead = getLeadResource();
const profile = getProfileResource();

const expands = ref([
    'card_back',
    'card_front',
    'id_back',
    'id_front',
    'dod',
    'ub'
]);

const idFrontStatus = ref(getDocumentInfo('id_front')?.status);
const idBackStatus = ref(getDocumentInfo('id_back')?.status);
const cardBackStatus = ref(getDocumentInfo('card_back')?.status);
const cardFrontStatus = ref(getDocumentInfo('card_front')?.status);
const dodStatus = ref(getDocumentInfo('dod')?.status);
const ubStatus = ref(getDocumentInfo('ub')?.status);

function updateStatus(type, status) {
    console.log(type, '  type.log');
    switch (type) {
        case 'id_front':
            idFrontStatus.value = status;
            break;
        case 'id_back':
            idBackStatus.value = status;
            break;
        case 'card_back':
            cardBackStatus.value = status;
            break;
        case 'card_front':
            cardFrontStatus.value = status;
            break;
        case 'dod':
            dodStatus.value = status;
            break;
        case 'ub':
            ubStatus.value = status;
            break;
    }
}

function expand(part) {
    if (expands.value.includes(part)) {
        expands.value.splice(expands.value.findIndex(item => item === part), 1);
    } else {
        expands.value.push(part);
    }

    profile.state.lead.profile = { expand: expands.value };
    profile.saveState();
}

function generateDocumentUrl(type) {
    const url = lead.model.specifics?.documents?.[type]?.url;
    return url ? `${process.env.VUE_APP_PRODUCT_SERVER}${url}` : null;
}

function getDocumentInfo(type) {
    const url = generateDocumentUrl(type);
    const status = lead.model.specifics?.documents?.[type]?.status;

    return {
        url,
        status: status === 'approve' ? 'approve' : status === 'reject' ? 'reject' : null
    };
}

const idFront = computed(() => getDocumentInfo('id_front'));
const idBack = computed(() => getDocumentInfo('id_back'));
const cardBack = computed(() => getDocumentInfo('card_back'));
const cardFront = computed(() => getDocumentInfo('card_front'));
const dod = computed(() => getDocumentInfo('dod'));
const ub = computed(() => getDocumentInfo('ub'));

const onFileChange = (e, target) => {
    let files = e.target.files || e.dataTransfer.files;
    return !files.length
        ? null
        : createImage(files[0], target);
};

const url2File = (url, filename, mimeType) => {
    return (fetch(url)
            .then(function (res) {return res.arrayBuffer();})
            .then(function (buf) {return new File([buf], `${filename}.jpeg`, { type: mimeType });})
    );
};

const createImage = (file, target) => {
    let img = new Image();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
        img.src = event.target.result;

        img.onload = function () {

            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            canvas.width = (img.width > img.height)
                ? 800
                : 500;
            canvas.height = img.height * (canvas.width / img.width);

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

            url2File(canvas.toDataURL('image/jpeg'), target, 'image/jpeg').then((encodedFile) => {                
                uploadDocument(lead.model.id, target, encodedFile).then((response) => {
                    if (lead.model.specifics?.documents) {
                        lead.model.specifics.documents[target] = {
                            url: response.url.url
                        };
                    }
                });
            });
        };
    };
};

function remove(id, type) {
    PopUp
    .setCaption(i18n.global.t('lead.remove_document', 'Remove Document'))
    .setMessage(
        `<p class="message">
            <b>${i18n.global.t('lead.document_remove_confirm', 'The document will be will be permanently deleted')}!</b>
         </p>`)
    .setAction(() => {
        kyc.remove(id, { type: type }).then(() => {lead.model.specifics.documents[type] = null;});
        PopUp.close();
    })
    .open(require('@/Ship/components/PopUps/Confirm'));

}

function approve(id, type) {
    lead.model.specifics.documents[type].status = 'approve';
    lead.save(lead.model.id, { specifics: { ...lead.model.specifics } });
    updateStatus(type, 'approve');
}

function reject(id, type) {
    lead.model.specifics.documents[type].status = 'reject';
    lead.save(lead.model.id, { specifics: { ...lead.model.specifics } });
    updateStatus(type, 'reject');
}

function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}

</script>

<template>
    <div class="kyc">
        <g-preloader v-if="lead.isLoading"/>
        <g-caption class="info" weight="500" size="4" v-if="!lead.state.length && !lead.isLoading">
            <div>{{ $t('base.nothing') }}</div>
        </g-caption>
        <div class="row">
            <div class="title" @click="scrollToElement('id_front')">{{ $t('lead.id_front') }}</div>
            <div class="actions">
                <label
                    class="g-button approve-btn with-tooltip"
                    v-bind:class="{ disabled: !profile.can('Lead KYCStatusEdit') }"
                    v-bind:data-tooltip="$t('base.upload')"
                >
                    <g-symbol name="upload" width="12" height="12" />
                    <input type="file"
                        v-on:change="onFileChange($event, 'id_front')"
                        accept="image/jpeg, image/png, image/webp"
                        hidden
                        v-bind:disabled="!profile.can('Lead KYCStatusEdit')"
                    />
                </label>
                <a
                    v-if="idFront.url"
                    v-bind:href="idFront.url"
                    class="g-button approve-btn with-tooltip"
                    v-bind:data-tooltip="$t('base.download')"
                    target="_blank"
                >
                    <g-symbol name="download" width="12" height="12"/>
                </a>
                <g-button class="remove-btn with-tooltip"
                          type="button"
                          :disabled="!idFront.url || !profile.can('Lead KYCStatusEdit')"
                          v-on:click="remove(lead.model.id, 'id_front')"
                          v-bind:data-tooltip="$t('base.delete', 'Delete')">
                    <g-symbol name="delete" width="12" height="12"/>
                </g-button>
                <input class="approve-btn visually-hidden"
                       type="radio"
                       id="approve-id-front"
                       :disabled="!idFront.url || !profile.can('Lead KYCStatusEdit')"
                       name="id-front"
                       v-on:click="approve(lead.model.id,'id_front')"
                       v-bind:checked="idFrontStatus === 'approve'">
                <label class="with-tooltip"
                       for="approve-id-front"
                       :class="{disabled: !idFront.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.approve', 'Approve')">
                    <g-symbol name="check" width="12" height="12"/>
                </label>

                <input class="reject-btn visually-hidden"
                       type="radio"
                       id="reject-id-front"
                       :disabled="!idFront.url && !profile.can('Lead KYCStatusEdit')"
                       name="id-front"
                       v-on:click="reject(lead.model.id, 'id_front')"
                       v-bind:checked="idFrontStatus === 'reject'">
                <label class="with-tooltip"
                       for="reject-id-front"
                       :class="{disabled: !idFront.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.reject', 'Reject')">
                    <g-symbol name="close" width="12" height="12"/>
                </label>
            </div>
        </div>
        <div class="row">
            <div class="title" @click="scrollToElement('id_back')">{{ $t('lead.id_back') }}</div>
            <div class="actions">
                <label
                    class="g-button approve-btn with-tooltip"
                    v-bind:class="{ disabled: !profile.can('Lead KYCStatusEdit') }"
                    v-bind:data-tooltip="$t('base.upload')"
                >
                    <g-symbol name="upload" width="12" height="12" />
                    <input type="file"
                        v-on:change="onFileChange($event, 'id_back')"
                        accept="image/jpeg, image/png, image/webp"
                        hidden
                        v-bind:disabled="!profile.can('Lead KYCStatusEdit')"
                    />
                </label>
                <a
                    v-if="idBack.url"
                    v-bind:href="idBack.url"
                    class="g-button approve-btn with-tooltip"
                    v-bind:data-tooltip="$t('base.download')"
                    target="_blank"
                >
                    <g-symbol name="download" width="12" height="12"/>
                </a>
                <g-button class="remove-btn with-tooltip"
                          type="button"
                          :disabled="!idBack.url || !profile.can('Lead KYCStatusEdit')"
                          v-on:click="remove(lead.model.id, 'id_back')"
                          v-bind:data-tooltip="$t('base.delete', 'Delete')">
                    <g-symbol name="delete" width="12" height="12"/>
                </g-button>
                <input class="approve-btn visually-hidden"
                       type="radio"
                       id="approve-id-back"
                       :disabled="!idBack.url || !profile.can('Lead KYCStatusEdit')"
                       name="id-back"
                       v-on:click="approve(lead.model.id,'id_back')"
                       v-bind:checked="idBackStatus === 'approve'">
                <label class="with-tooltip"
                       for="approve-id-back"
                       :class="{disabled: !idBack.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.approve', 'Approve')">
                    <g-symbol name="check" width="12" height="12"/>
                </label>
                <input class="reject-btn visually-hidden"
                       type="radio"
                       id="reject-id-back"
                       :disabled="!idBack.url || !profile.can('Lead KYCStatusEdit')"
                       name="id-back"
                       v-on:click="reject(lead.model.id, 'id_back')"
                       v-bind:checked="idBackStatus === 'reject'">
                <label class="with-tooltip"
                       for="reject-id-back"
                       :class="{disabled: !idBack.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.reject', 'Reject')">
                    <g-symbol name="close" width="12" height="12"/>
                </label>
            </div>
        </div>
        <div class="row">
            <div @click="scrollToElement('card_front')" class="title">{{ $t('lead.card_front') }}</div>
            <div class="actions">
                <label
                    class="g-button approve-btn with-tooltip"
                    v-bind:class="{ disabled: !profile.can('Lead KYCStatusEdit') }"
                    v-bind:data-tooltip="$t('base.upload')"
                >
                    <g-symbol name="upload" width="12" height="12" />
                    <input type="file"
                        v-on:change="onFileChange($event, 'card_front')"
                        accept="image/jpeg, image/png, image/webp"
                        hidden
                        v-bind:disabled="!profile.can('Lead KYCStatusEdit')"
                    />
                </label>
                <a
                    v-if="cardFront.url"
                    v-bind:href="cardFront.url"
                    class="g-button approve-btn with-tooltip"
                    v-bind:data-tooltip="$t('base.download')"
                    target="_blank"
                >
                    <g-symbol name="download" width="12" height="12"/>
                </a>
                <g-button class="remove-btn with-tooltip"
                          type="button"
                          :disabled="!cardFront.url || !profile.can('Lead KYCStatusEdit')"
                          v-on:click="remove(lead.model.id, 'card_front' )"
                          v-bind:data-tooltip="$t('base.delete', 'Delete')">
                    <g-symbol name="delete" width="12" height="12"/>
                </g-button>
                <input class="approve-btn visually-hidden"
                       type="radio"
                       id="approve-card-front"
                       :disabled="!cardFront.url || !profile.can('Lead KYCStatusEdit')"
                       name="card-front"
                       v-on:click="approve(lead.model.id,'card_front')"
                       v-bind:checked="cardFrontStatus === 'approve'">
                <label class="with-tooltip"
                       for="approve-card-front"
                       :class="{disabled: !cardFront.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.approve', 'Approve')">
                    <g-symbol name="check" width="12" height="12"/>
                </label>
                <input class="reject-btn visually-hidden"
                       type="radio"
                       id="reject-card-front"
                       :disabled="!cardFront.url || !profile.can('Lead KYCStatusEdit')"
                       name="card-front"
                       v-on:click="reject(lead.model.id, 'card_front')"
                       v-bind:checked="cardFrontStatus === 'reject'">
                <label class="with-tooltip"
                       for="reject-card-front"
                       :class="{disabled: !cardFront.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.reject', 'Reject')">
                    <g-symbol name="close" width="12" height="12"/>
                </label>
            </div>
        </div>
        <div class="row">
            <div @click="scrollToElement('card_back')" class="title">{{ $t('lead.card_back') }}</div>
            <div class="actions">
                <label
                    class="g-button approve-btn with-tooltip"
                    v-bind:class="{ disabled: !profile.can('Lead KYCStatusEdit') }"
                    v-bind:data-tooltip="$t('base.upload')"
                >
                    <g-symbol name="upload" width="12" height="12" />
                    <input type="file"
                        v-on:change="onFileChange($event, 'card_back')"
                        accept="image/jpeg, image/png, image/webp"
                        hidden
                        v-bind:disabled="!profile.can('Lead KYCStatusEdit')"
                    />
                </label>
                <a
                    v-if="cardBack.url"
                    v-bind:href="cardBack.url"
                    class="g-button approve-btn with-tooltip"
                    v-bind:data-tooltip="$t('base.download')"
                    target="_blank"
                >
                    <g-symbol name="download" width="12" height="12"/>
                </a>
                <g-button class="remove-btn with-tooltip"
                          type="button"
                          :disabled="!cardBack.url || !profile.can('Lead KYCStatusEdit')"
                          v-on:click="remove(lead.model.id, 'card_back')"
                          v-bind:data-tooltip="$t('base.delete', 'Delete')">
                    <g-symbol name="delete" width="12" height="12"/>
                </g-button>
                <input class="approve-btn visually-hidden"
                       type="radio"
                       :disabled="!cardBack.url || !profile.can('Lead KYCStatusEdit')"
                       id="approve-card-back"
                       name="card-back"
                       v-on:click="approve(lead.model.id,'card_back')"
                       v-bind:checked="cardBackStatus === 'approve'">
                <label class="with-tooltip"
                       for="approve-card-back"
                       :class="{disabled: !cardBack.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.approve', 'Approve')">
                    <g-symbol name="check" width="12" height="12"/>
                </label>
                <input class="reject-btn visually-hidden"
                       type="radio"
                       :disabled="!cardBack.url || !profile.can('Lead KYCStatusEdit')"
                       id="reject-card-back"
                       name="card-back"
                       v-on:click="reject(lead.model.id, 'card_back')"
                       v-bind:checked="cardBackStatus === 'reject'">
                <label class="with-tooltip"
                       for="reject-card-back"
                       :class="{disabled: !cardBack.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.reject', 'Reject')">
                    <g-symbol name="close" width="12" height="12"/>
                </label>
            </div>
        </div>
        <div class="row">
            <div @click="scrollToElement('dod')" class="title">{{ $t('lead.dod') }}</div>
            <div class="actions">
                <label
                    class="g-button approve-btn with-tooltip"
                    v-bind:class="{ disabled: !profile.can('Lead KYCStatusEdit') }"
                    v-bind:data-tooltip="$t('base.upload')"
                >
                    <g-symbol name="upload" width="12" height="12" />
                    <input type="file"
                        v-on:change="onFileChange($event, 'dod')"
                        accept="image/jpeg, image/png, image/webp"
                        hidden
                        v-bind:disabled="!profile.can('Lead KYCStatusEdit')"
                    />
                </label>
                <a
                    v-if="dod.url"
                    v-bind:href="dod.url"
                    class="g-button approve-btn with-tooltip"
                    v-bind:data-tooltip="$t('base.download')"
                    target="_blank"
                >
                    <g-symbol name="download" width="12" height="12"/>
                </a>
                <g-button class="remove-btn with-tooltip"
                          type="button"
                          :disabled="!dod.url || !profile.can('Lead KYCStatusEdit')"
                          v-on:click="remove(lead.model.id, 'dod')"
                          v-bind:data-tooltip="$t('base.delete', 'Delete')">
                    <g-symbol name="delete" width="12" height="12"/>
                </g-button>
                <input class="approve-btn visually-hidden"
                       type="radio"
                       id="approve-dod"
                       :disabled="!dod.url || !profile.can('Lead KYCStatusEdit')"
                       name="dod"
                       v-on:click="approve(lead.model.id,'dod')"
                       v-bind:checked="dodStatus === 'approve'">
                <label class="with-tooltip"
                       for="approve-dod"
                       :class="{disabled: !dod.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.approve', 'Approve')">
                    <g-symbol name="check" width="12" height="12"/>
                </label>
                <input class="reject-btn visually-hidden"
                       type="radio"
                       id="reject-dod"
                       :disabled="!dod.url || !profile.can('Lead KYCStatusEdit')"
                       name="dod"
                       v-on:click="reject(lead.model.id, 'dod')"
                       v-bind:checked="dodStatus === 'reject'">
                <label class="with-tooltip"
                       for="reject-dod"
                       :class="{disabled: !dod.url || !profile.can('Lead KYCStatusEdit')}"
                       v-bind:data-tooltip="$t('base.reject', 'Reject')">
                    <g-symbol name="close" width="12" height="12"/>
                </label>
            </div>
        </div>
        <div class="row">
            <div @click="scrollToElement('ub')" class="title">{{ $t('lead.ub') }}</div>
            <div class="actions">
                <label
                    class="g-button approve-btn with-tooltip"
                    v-bind:class="{ disabled: !profile.can('Lead KYCStatusEdit') }"
                    v-bind:data-tooltip="$t('base.upload')"
                >
                    <g-symbol name="upload" width="12" height="12" />
                    <input type="file"
                        v-on:change="onFileChange($event, 'ub')"
                        accept="image/jpeg, image/png, image/webp"
                        hidden
                        v-bind:disabled="!profile.can('Lead KYCStatusEdit')"
                    />
                </label>
                <a
                    v-if="ub.url"
                    v-bind:href="ub.url"
                    class="g-button approve-btn with-tooltip"
                    v-bind:data-tooltip="$t('base.download')"
                    target="_blank"
                >
                    <g-symbol name="download" width="12" height="12"/>
                </a>
                <g-button class="remove-btn with-tooltip"
                          type="button"
                          :disabled="!ub.url"
                          v-on:click="remove(lead.model.id, 'ub')"
                          v-bind:data-tooltip="$t('base.delete', 'Delete')">
                    <g-symbol name="delete" width="12" height="12"/>
                </g-button>
                <input class="approve-btn visually-hidden"
                       type="radio"
                       id="approve-ub"
                       :disabled="!ub.url"
                       name="ub"
                       v-on:click="approve(lead.model.id,'ub')"
                       v-bind:checked="ubStatus === 'approve'">
                <label class="with-tooltip"
                       for="approve-ub"
                       :class="{disabled: !ub.url}"
                       v-bind:data-tooltip="$t('base.approve', 'Approve')">
                    <g-symbol name="check" width="12" height="12"/>
                </label>
                <input class="reject-btn visually-hidden"
                       type="radio"
                       id="reject-ub"
                       :disabled="!ub.url"
                       name="ub"
                       v-on:click="reject(lead.model.id, 'ub')"
                       v-bind:checked="ubStatus === 'reject'">
                <label class="with-tooltip"
                       for="reject-ub"
                       :class="{disabled: !ub.url}"
                       v-bind:data-tooltip="$t('base.reject', 'Reject')">
                    <g-symbol name="close" width="12" height="12"/>
                </label>
            </div>
        </div>

        <fieldset id="id_front" class="block" v-bind:class="{is_expand: expands.includes('id_front')}">
            <legend v-on:click="expand('id_front')">
                {{ $t('lead.id_front') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>

            <div class="container expand">
                <div class="image-wrapper">
                    <div class="image" :style="`background-image: url(${idFront.url});`" alt="Id Front"/>
                </div>
            </div>
        </fieldset>
        <fieldset id="id_back" class="block" v-bind:class="{is_expand: expands.includes('id_back')}">
            <legend v-on:click="expand('id_back')">
                {{ $t('lead.id_back') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>

            <div class="container expand">
                <div class="image-wrapper">
                    <div class="image" :style="`background-image: url(${idBack.url});`" alt="Id Back"/>
                </div>
            </div>
        </fieldset>
        <fieldset id="card_front" class="block" v-bind:class="{is_expand: expands.includes('card_front')}">
            <legend v-on:click="expand('card_front')">
                {{ $t('lead.card_front') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>

            <div class="container expand">
                <div class="image-wrapper">
                    <div class="image" :style="`background-image: url(${cardFront.url});`" alt="Card Front"/>
                </div>
            </div>
        </fieldset>
        <fieldset id="card_back" class="block" v-bind:class="{is_expand: expands.includes('card_back')}">
            <legend v-on:click="expand('card_back')">
                {{ $t('lead.card_back') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>

            <div class="container expand">
                <div class="image-wrapper">
                    <div class="image" :style="`background-image: url(${cardBack.url});`" alt="Card Back"/>
                </div>
            </div>
        </fieldset>
        <fieldset id="dod" class="block" v-bind:class="{is_expand: expands.includes('dod')}">
            <legend v-on:click="expand('dod')">
                {{ $t('lead.dod') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>

            <div class="container expand">
                <div class="image-wrapper">
                    <div class="image" :style="`background-image: url(${dod.url});`" alt="DOD"/>
                </div>
            </div>
        </fieldset>
        <fieldset id="ub" class="block" v-bind:class="{is_expand: expands.includes('ub')}">
            <legend v-on:click="expand('ub')">
                {{ $t('lead.ub') }}
                <g-symbol name="arrow-down" width="24" height="24"/>
            </legend>

            <div class="container expand">
                <div class="image-wrapper">
                    <div class="image" :style="`background-image: url(${ub.url});`" alt="UB"/>
                </div>
            </div>
        </fieldset>

    </div>
</template>

<style scoped lang="scss">
fieldset {
    position: relative;
    padding: 20px 10px;
    border-top: 1px solid var(--separator-color, $separator-color);

    & > legend {
        padding: 0 4px;
        font-size: 1.2rem;
        cursor: pointer;
        color: var(--warning-hover, $warning-hover);

        & > .g-symbol {
            right: 6px;
            padding: 2px;
            position: absolute;
            border-radius: 50%;
            border: 1px solid var(--separator-color, $separator-color);
            fill: var(--main-text-color, $main-text-color);
            background-color: var(--bar-bg, $bar-bg);
            transform: rotate(180deg);
            transition: transform 0.3s;
        }
    }

    & > .expand {
        overflow-y: auto;
        max-height: 400px;
        transition: max-height 0.3s;
    }

    & > .container {
        align-items: end;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;

        & > .full-row {
            grid-column: 1/3;
        }
    }

    &:not(.is_expand) {
        & > legend > .g-symbol {
            transform: rotate(0deg);
            fill: var(--warning-hover, $warning-hover);
        }

        & > .expand {
            max-height: 0;
            overflow: hidden;
        }
    }
}

.title {
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}

.g-button {
    width: 29px;
    height: 29px !important;
    color: white;
    margin: 0 4px;
    font-size: 0 !important;
    background-color: transparent;
    fill: var(--title-color, $title-color);
    border: 1px solid var(--title-color, $title-color);
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--btn-rounding, $btn-rounding);
    transition: background-color 0.2s, color 0.2s;

    &:deep(svg) {
        vertical-align: middle;
        transition: fill 0.2s;
    }
}

.actions {
    display: flex;

    & .with-tooltip:last-child {
        &::after,
        &::before {
            transform: translate(-80%, 120%);
            left: 70%;
        }
    }
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;

    &:last-of-type {
        margin-bottom: 20px;
    }
}

.image-wrapper {
    border: 1px solid var(--body-bg, $body-bg);
    background-color: var(--body-bg, $body-bg);
    border-radius: 5%;
}

.image {
    width: 400px;
    padding-top: 63.03%;
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5%;
}

input[type='radio'] {
    display: none;
}

label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29px;
    height: 29px;
    color: white;
    margin: 0 4px;
    font-size: 0;
    background-color: transparent;
    fill: var(--title-color, $title-color);
    border: 1px solid var(--title-color, $title-color);
    position: relative;
    cursor: pointer;
    border-radius: var(--btn-rounding, 5px);
}

.remove-btn:hover {
    background-color: var(--warning, $warning);
    border: 1px solid var(--title-color, $title-color);
}

.approve-btn:checked + label.with-tooltip,
.reject-btn:checked + label.with-tooltip,
.approve-btn:not(.disabled):hover {
    background-color: var(--success, $success);
    border: 1px solid var(--main-text-color, #aabfcf);
}

.reject-btn:checked + label.with-tooltip {
    background-color: var(--danger, $danger);
}

.approve-btn + label.with-tooltip:not(.disabled):hover {
    background-color: var(--success, $success);
}

.reject-btn + label.with-tooltip:not(.disabled):hover {
    background-color: var(--danger, $danger);
}

.with-tooltip {
    position: relative;
    fill: var(--title-color, $title-color);

    &::after,
    &::before {
        transform: translate(-50%, 120%);
        left: 50%;
        top: 0;
    }
}

.disabled {
    opacity: 0.5;

    &::after,
    &::before {
        display: none;
    }
}
</style>
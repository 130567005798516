import { STACK, ApiClient } from '@/Ship';

export default function (id, target, file) {
    return STACK.push(() => {
        return ApiClient.post(`profile/${id}/document/${target}`, { image: file }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    });
}

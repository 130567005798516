<script setup>
import { defineProps, defineEmits, reactive, watch, computed, ref } from 'vue';
import { useShipActions, verifyField } from '@/Ship';
import { useTradingActions } from '@trading';
import { useLeadActions } from '@lead';
import { useSettingsActions } from "@settings";

const props = defineProps(['payload']);
const emit = defineEmits(['performed', 'close']);

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource();

const onlyNumberInput = useShipActions('onlyNumberInput');

const marketCurrency = (props.payload.pair.market === 'forex' || props.payload.pair.market === 'commodities') ? 'USD' : 'USDT';
const counterPrice = props.payload.pair.counter !== marketCurrency
    ? props.payload.position.direction === 'Long'
        ? props.payload.pl / ((props.payload.close_price - props.payload.position.entry_price) * props.payload.position.value)
        : props.payload.pl / ((props.payload.position.entry_price - props.payload.close_price) * props.payload.position.value)
    : 1;

function profitCalculate() {
    const priceDelta = position.direction === 'Long'
        ? history.close_price - position.entry_price
        : position.entry_price - history.close_price;

    //const swap = ((position.usdt_amount * position.swap) / 100);
    const swap = ((position.usdt_amount * position.swap) / 100) * counterPrice;
    const pl = ((priceDelta * counterPrice) * position.value) - swap;

    return Number(pl.toFixed(5));
}

const isLock = ref(false);
const errors = reactive({
    entryPrice: null,
    closePrice: null,
    profit: null,
    takeProfit: null,
    stopLoss: null,
    openedAt: null,
    closeAt: null,
});

watch(errors, () => {
    isLock.value = false;
    for (const key in errors) {
        if (errors[key] !== null) {
            isLock.value = true;
            break;
        }
    }
});

const pair = `${props.payload.pair.base}:${props.payload.pair.counter}`;

const targetPayload = reactive({
    position: {}
});
const history = new Proxy(targetPayload, {
    get(target, key) {
        return key in target
            ? target[key]
            : props.payload[key];
    },
    set(target, key, value) {
        if (value != target[key]) {
            target[key] = value;
        }

        return true;
    }
});
const position = new Proxy(targetPayload, {
    get(target, key) {
        return key in target.position
            ? target.position[key]
            : props.payload.position[key];
    },
    set(target, key, value) {
        if (value != target.position[key]) {
            target.position[key] = value;
        }

        return true;
    }
});

const profit = computed({
    get() {
        return history.pl;
    },
    set(value) {
        history.pl = Number(value);

        errors.profit = ((lead.model.balance - Number(props.payload.pl) + history.pl) < 0)
            ? 'Not enough Balance!'
            : errors.profit = null;

        return true;
    }
});

const closePrice = computed({
    get() {
        return Number(history.close_price);
    },
    set(value) {
        history.close_price = Number(value);
        profit.value = profitCalculate();

        errors.closePrice = (value > 0)
            ? null
            : errors.closePrice = 'Close Price must be greater than zero';

        return true;
    }
});

const entryPrice = computed({
    get() {
        return Number(position.entry_price);
    },
    set(value) {
        position.entry_price = Number(value);
        position.value = Number((position.total_amount / value).toFixed(5));
        profit.value = profitCalculate();

        errors.entryPrice = (value > 0)
            ? null
            : errors.entryPrice = 'Entry Price must be greater than zero';

        return true;
    }
});

const amount = computed({
    get() {
        return position.amount;
    },
    set(value) {
        const marketCurrency = props.payload.pair.market === 'forex' ? 'USD' : 'USDT';

        const usdtAmount = (props.payload.pair.counter !== marketCurrency)
            ? value * position.entry_price // props.payload.entry_price
            : value;

        position.amount = Number(value);
        position.usdt_amount = usdtAmount;
        position.total_amount = value * position.leverage;
        position.value = Number((position.total_amount / position.entry_price).toFixed(5));
        profit.value = profitCalculate();

        errors.amount = (value > 0)
            ? null
            : 'Amount must be greater than zero';

        return true;
    }
});

const leverage = computed({
    get() {
        return position.leverage;
    },
    set(value) {
        position.leverage = Number(value);
        position.total_amount = position.amount * value;
        position.value = Number((position.total_amount / position.entry_price).toFixed(5));
        profit.value = profitCalculate();

        return true;
    }
});

const value = computed({
    get() {
        return position.value;
    },
    set(value) {
        position.value = Number(value);
        const price = (position.amount * position.leverage) / value;
        position.entry_price = ['USDT', 'USD'].includes(String(props.payload.pair.counter))
            ? position.entry_price = Number(price.toFixed(2))
            : position.entry_price = Number(price.toFixed(5));
        profit.value = profitCalculate();

        errors.value = (value > 0)
            ? null
            : 'Value must be greater than zero';

        return true;
    }
});

const direction = computed({
    get() {
        return position.direction;
    },
    set(value) {
        position.direction = value;
        const tmp = position.take_profit;
        position.take_profit = position.stop_loss;
        position.stop_loss = tmp;
        profit.value = profitCalculate();

        return true;
    }
});

const takeProfit = computed({
    get() {
        return position.take_profit;
    },
    set(value) {
        position.take_profit = value;

        if (value > 0) {
            const factor = position.direction === 'Long' ? 1 : -1;

            errors.takeProfit = ((position.entry_price - value) * factor < 0)
                ? null
                : `Take Profit mast be ${(factor > 0 ? 'greater' : 'less')} than the Entry Price!`;
        } else {
            errors.takeProfit = 'Take Profit must be greater than zero';
        }

        return true;
    }
});

const stopLoss = computed({
    get() {
        return position.stop_loss;
    },
    set(value) {
        position.stop_loss = value;

        if (value > 0) {
            const factor = position.direction === 'Long' ? -1 : 1;

            errors.stopLoss = ((position.entry_price - value) * factor < 0)
                ? null
                : errors.stopLoss = `Stop Loss must be ${(factor > 0 ? 'greater' : 'less')} than the Entry Price!`;
        } else {
            errors.stopLoss = 'Stop Loss must be greater than zero';
        }

        return true;
    }
});

const swap = computed({
    get() {
        return Number(position.swap);
    },
    set(value) {
        position.swap = Number(value);
        profit.value = profitCalculate();
        errors.swap = verifyField(value, ['require']);
        return true;
    }
});

function calculateSwap(opened, closed) {

    const dayCount = ((closed - opened) / 86400000) >> 0;

    return {
        crypto: (lead.model.specifics?.crypto_fee || settings.general.defaultCryptoFee || 0.001),
        stocks: (lead.model.specifics?.stock_fee || settings.general.defaultStockFee || 0.001),
        forex: (lead.model.specifics?.forex_fee || settings.general.defaultForexFee || 0.001),
        commodities: (lead.model.specifics?.forex_fee || settings.general.defaultForexFee || 0.001),
    }[props.payload.pair.market] * dayCount;
}

const openedAt = computed({
    get() {
        return position.created_at;
    },
    set(value) {
        position.created_at = value;
        errors.openedAt = verifyField(position.created_at, ['require']);
        const opened = new Date(value);
        opened.setUTCHours(0, 0, 0);
        const closed = Date.parse(closedAt.value.replace(/\./g, '-'));
        swap.value = Number(calculateSwap(opened.valueOf(), closed).toFixed(3));

        return true;
    }
});

const closedAt = computed({
    get() {
        return history.created_at;
    },
    set(value) {
        history.created_at = value;
        errors.closeAt = verifyField(history.created_at, ['require']);
        const opened = new Date(openedAt.value);
        opened.setUTCHours(0, 0, 0);
        const closed = Date.parse(value.replace(/\./g, '-'));

        swap.value = Number(calculateSwap(opened.valueOf(), closed).toFixed(3));

        return true;
    }
});

const getHistoryResource = useTradingActions('getPositionHistoryResource');
const resource = getHistoryResource();

function savePosition() {

    for (const key in targetPayload) {
        if (key === 'position') {
            for (const positionKey in targetPayload.position) {
                if (typeof targetPayload.position[positionKey] === 'number') {
                    if (targetPayload.position[positionKey] === Number(props.payload.position[positionKey])) {
                        delete targetPayload.position[positionKey];
                    }
                } else if (targetPayload.position[positionKey] === props.payload.position[positionKey]) {
                    delete targetPayload.position[positionKey];
                }
            }
            continue;
        }

        if (typeof targetPayload[key] === 'number') {
            if (targetPayload[key] === Number(props.payload[key])) {
                delete targetPayload[key];
            }
        } else if (targetPayload[key] === props.payload[key]) {
            delete targetPayload[key];
        }
    }

    resource.save(props.payload.id, targetPayload).then(() => {
        const cpPosition = {};

        for (const key in targetPayload) {
            if (Object.prototype.hasOwnProperty.call(targetPayload, key)) {
                cpPosition[key] = targetPayload[key];
            }
        }
        Object.assign(resource.state[resource.index].position, cpPosition.position);
        Object.assign({},resource.state[resource.index], targetPayload);
        emit('close');
    });
}

</script>

<template>
    <form v-on:submit.prevent="savePosition">
        <g-field v-model="position.id" label="ID" readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>
        <g-field v-model="pair" v-bind:label="payload.pair.market.toUpperCase()" readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>
        <g-select v-model="direction"
                  v-bind:label="$t('trading.direction', 'Direction')"
                  v-bind:options="[{value: 'Long'},{value: 'Short'}]"
                  option-text="value"
                  option-value="value"/>

        <g-select v-model="history.status"
                  v-bind:label="$t('trading.status', 'Status')"
                  v-bind:options="[{value: 'Trader Close'},{value: 'Broker Close'}, {value: 'Take Profit'}, {value: 'Stop Loss'}, {value: 'Liquidation'}]"
                  option-text="value"
                  option-value="value"/>

        <g-field v-model="entryPrice"
                 v-bind:error="errors.entryPrice"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('trading.entry_price', 'Entry Price') + ', ' + payload.pair.counter"/>
        <g-field v-model="closePrice"
                 v-bind:error="errors.closePrice"
                 v-bind:label="$t('trading.close_price', 'Close Price') + ', ' + payload.pair.counter"/>

        <g-field v-model="amount"
                 v-bind:error="errors.amount"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('trading.amount', 'Amount') + ', ' + payload.pair.counter"/>
        <g-select v-model="leverage"
                  v-bind:label="$t('trading.leverage', 'Leverage')"
                  v-bind:options="[{n: '✕1', v: 1},{n: '✕2', v: 2}, {n: '✕3', v: 3}, {n: '✕5', v: 5}, {n: '✕10', v: 10}, {n: '✕15', v: 15}, {n: '✕20', v: 20}]"
                  option-text="n"
                  option-value="v"/>
        <!--<g-field v-model="position.usdt_amount" v-bind:label="$t('trading.usdt_amount', 'USDT Amount')"/>-->
        <g-field v-model="position.total_amount"
                 v-bind:label="$t('trading.total', 'Total') + ', ' + payload.pair.counter" readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>
        <g-field v-model="value"
                 v-bind:error="errors.value"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('trading.value', 'Value') + ', ' + payload.pair.base"/>
        <g-field v-model="profit"
                 v-bind:error="errors.profit"
                 v-bind:label="$t('trading.pl', 'Profit & Loss') + ', USDT'"
                 readonly>
            <g-symbol name="lock" width="18" height="18"/>
        </g-field>
        <g-field v-model="swap"
                 v-on:keypress="onlyNumberInput"
                 :error="errors.swap"
                 v-bind:label="$t('trading.swap', 'Swap')"/>
        <g-field v-model="takeProfit"
                 v-on:keypress="onlyNumberInput"
                 v-bind:error="errors.takeProfit"
                 v-bind:label="$t('trading.take_profit', 'Take Profit') + ', ' + payload.pair.counter"/>
        <g-field v-model="stopLoss"
                 v-bind:error="errors.stopLoss"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('trading.stop_loss', 'Stop Loss') + ', ' + payload.pair.counter"/>
        <g-field v-model="openedAt"
                 :error="errors.openedAt"
                 v-bind:label="$t('base.opened_at', 'Opened at')"/>
        <g-field v-model="closedAt"
                 :error="errors.closeAt"
                 v-bind:label="$t('base.closed_at', 'Closed at')"/>
        <div></div>
        <g-flex class="btn-bar" gap="5">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="isLock">{{ $t('base.save', 'Save') }}</g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
form {
    max-width: 480px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
}

.g-button {
    flex-grow: 1;
}
</style>
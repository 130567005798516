<script setup>

import { computed, defineEmits, onMounted, reactive, ref } from 'vue';
import { useProfileActions } from '@profile';
import { useLeadActions, useLeadResources } from '@lead';

const PRESETS_MAX_NUMBER = 10;

const emit = defineEmits(['close']);

const filters = useLeadResources('FilterState');

const {
    getFilters,
    getLeadResource
} = useLeadActions();
const leads = getLeadResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const filterset = reactive({});
const presetName = ref('');
const showFiltersNamePopover = ref(false);
const presets = ref([]);

const canAddPreset = computed(() => {
    return presets.value.length < PRESETS_MAX_NUMBER;
});

onMounted(() => {
    getFilters().then(response => {
        Object.assign(filterset, response.data);
        Object.assign(leads.filterset, response.data);
    })

    try {
        presets.value = Object.keys(JSON.parse(localStorage.getItem('filter_presets')));
    } catch (error) {
        return;
    }
})

function filtrate() {

    Object.keys(filters.state).forEach(section => {
        if (!Object.keys(filters.state[section]).length) {
            delete filters.state[section];
        }
    });

    leads.setFilters(filters.state);
    leads.setPage(1);
    leads.load();

    const brandId = profile.state.env.brand || 0;
    profile.state.lead.filters[brandId] = filters.state;
    profile.saveState();
    emit('close');
}

function togglePopover(state) {
    showFiltersNamePopover.value = state;
    if (!state) presetName.value = '';
}

function savePreset() {
    const preset = {
        [presetName.value]: { ...filters.state }
    }

    if (!presets.value.some(p => p === presetName.value)) {
        presets.value.push(presetName.value);
    }

    const prsts = localStorage.getItem('filter_presets');
    if (prsts) {
        try {
            localStorage.setItem('filter_presets', JSON.stringify(Object.assign(JSON.parse(prsts), preset)));
        } catch (error) {
            console.error(error);
        }
    } else {
        localStorage.setItem('filter_presets', JSON.stringify(preset));
    }
    
    togglePopover(false);
}

function setPreset(name) {
    try {
        const preset = JSON.parse(localStorage.getItem('filter_presets'))[name];
        if (preset) filters.state = preset;
    } catch (error) {
        return;
    }
}

function close() {
    Object.keys(filters.state).forEach(filter => {
        delete filters.state[filter];
    });
    Object.assign(filters.state, leads.filters);

    emit('close');
}

</script>

<template>
    <div v-if="presets.length > 0" class="preset-buttons">
        <button
            v-for="(name) in presets"
            v-bind:key="name"
            type="button"
            v-on:click="setPreset(name)"
        >{{ name }}</button>
    </div>

    <form class="filters" v-on:submit.prevent="filtrate">
        <div class="container custom-scrollbar">

            <!-- CONVERSION STATUS -->
            <g-filterset v-model="filters.state.conversion_status"
                        v-bind:label="$t('filters.conversion_status', 'Conversion status')"
                        v-bind:filterset="filterset.conversion_status"
                        is-select-all
                        is-search
                        expanded/>

            <!-- RETENTION STATUS -->
            <g-filterset v-model="filters.state.retention_status"
                        v-bind:label="$t('filters.retention_status', 'Retention status')"
                        v-bind:filterset="filterset.retention_status"
                        is-select-all
                        is-search
                        expanded/>


            <!-- CAMPAIGN -->
            <g-filterset v-model="filters.state.campaigns"
                        v-bind:label="$t('filters.campaigns', 'Campaigns')"
                        value-field="id"
                        v-bind:key-field="profile.can('Lead RealCampaignView') ? 'key' : 'alias'"
                        v-bind:filterset="filterset.campaigns"
                        is-select-all
                        is-search
                        expanded/>

            <div>
                <!-- FTD -->
                <g-filterset v-model="filters.state.is_ftd"
                            v-bind:label="$t('filters.is_ftd', 'Is FTD')"
                            value-field="value"
                            v-bind:filterset="filterset.is_ftd"
                            is-select-all
                            expanded/>

                <!-- PHONE CONFIRMED -->
                <g-filterset v-model="filters.state.phone_confirmed"
                            v-bind:label="$t('filters.phone_confirmed', 'Phone confirmed')"
                            value-field="value"
                            v-bind:filterset="filterset.phone_confirmed"
                            is-select-all
                            expanded/>

                <!-- EMAIL CONFIRMED -->
                <g-filterset v-model="filters.state.email_confirmed"
                            v-bind:label="$t('filters.email_confirmed', 'Email confirmed')"
                            value-field="value"
                            v-bind:filterset="filterset.email_confirmed"
                            is-select-all
                            expanded/>

                <!-- Is TEST -->
                <g-filterset v-model="filters.state.is_test"
                            v-bind:label="$t('filters.is_test', 'Is Test')"
                            value-field="value"
                            v-bind:filterset="filterset.is_test"
                            is-select-all
                            expanded/>
            </div>

            <!-- TEAM -->
            <g-filterset v-model="filters.state.teams"
                        v-bind:label="$t('filters.teams', 'Teams')"
                        v-bind:filterset="filterset.teams"
                        value-field="id"
                        is-select-all
                        is-search
                        expanded/>

            <!-- SALE -->
            <g-filterset v-model="filters.state.sales"
                        v-bind:label="$t('filters.sales', 'Sale agents')"
                        value-field="id"
                        v-bind:filterset="filterset.sales"
                        is-select-all
                        is-search
                        expanded/>

            <!-- RETENTION -->
            <g-filterset v-model="filters.state.retentions"
                        v-bind:label="$t('filters.retentions', 'Retention agents')"
                        value-field="id"
                        v-bind:filterset="filterset.retentions"
                        is-select-all
                        is-search
                        expanded/>

            <!-- AFFILIATE -->
            <g-filterset v-model="filters.state.affiliates"
                        v-bind:label="$t('filters.affiliates', 'Affiliates')"
                        v-bind:filterset="filterset.affiliates"
                        value-field="id"
                        is-select-all
                        is-search
                        expanded/>

            <!-- COUNTRY -->
            <g-filterset v-model="filters.state.countries"
                        v-bind:label="$t('filters.countries', 'Countries')"
                        value-field="code"
                        v-bind:filterset="filterset.countries"
                        is-select-all
                        is-search
                        expanded/>

            <!-- TIMEZONE -->
            <g-filterset v-model="filters.state.timezone"
                        v-bind:label="$t('filters.timezone', 'Timezone')"
                        v-bind:filterset="filterset.timezone"
                        is-select-all
                        is-search
                        expanded/>

            <!-- LANGUAGE -->
            <g-filterset v-model="filters.state.language"
                        v-bind:label="$t('filters.languages', 'Languages')"
                        v-bind:filterset="filterset.language"
                        is-select-all
                        expanded/>

            <!-- ACCOUNT LEVEL -->
            <g-filterset v-model="filters.state.account_level"
                        v-bind:label="$t('filters.level', 'Account level')"
                        v-bind:filterset="filterset.account_level"
                        is-select-all
                        expanded/>

            <!-- KYC STATUS -->
            <g-filterset v-model="filters.state.kyc_status"
                        v-bind:label="$t('filters.kyc_status', 'KYC status')"
                        v-bind:filterset="filterset.kyc_status"
                        is-select-all
                        expanded/>

            <!-- GENDER -->
            <g-filterset v-model="filters.state.gender"
                        v-bind:label="$t('filters.gender', 'Gender')"
                        v-bind:filterset="filterset.gender"
                        is-select-all
                        expanded/>

            <!-- MARITAL STATUS -->
            <g-filterset v-model="filters.state.marital_status"
                        v-bind:label="$t('filters.marital_status', 'Marital Status')"
                        v-bind:filterset="filterset.marital_status"
                        is-select-all
                        expanded/>

        </div>

        <g-flex class="btn-bar" justify="end" gap="5">
            <g-button class="cancel-btn" type="button" v-on:click="close">{{ $t('base.cancel') }}</g-button>
            <div v-if="canAddPreset" class="popover">
                <g-button class="save-preset-btn" type="button" v-on:click="togglePopover(true)">{{ $t('filters.save_filters') }}</g-button>
                <div class="popover-body" v-show="showFiltersNamePopover">
                    <button type="button" class="popover-close" v-on:click="togglePopover(false)">
                        <g-symbol name="close" width="12" height="12" />
                    </button>
                    <g-flex direction="column" gap="4" align="end">
                        <g-field v-model="presetName" v-bind:label="$t('filters.filters_name')" />
                        <g-button type="button" class="save-btn" v-on:click="savePreset">{{ $t('base.save') }}</g-button>
                    </g-flex>
                </div>
            </div>
            <g-button class="save-btn">{{ $t('base.apply') }}</g-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.filters {
    width: 1124px;
}

.container {
    overflow: auto;
    padding: 0 10px;
    max-height: calc(100vh - 220px);
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
}

.btn-bar {
    padding-top: 20px;

    & > .g-button {
        padding: 0 20px;
        min-width: 100px;
    }
}

.popover {
    position: relative;

    .popover-body {
        position: absolute;
        z-index: 5;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translate(-50%, 0);
        padding: 10px;
        padding-top: 20px;
        border-radius: 4px;
        border: 1px solid var(--input-border, $input-border);
        min-width: 250px;
        background-color: var(--popup-bg, $popup-bg);

        .g-field {
            width: 100%;
        }
    }

    .popover-close {
        position: absolute;
        z-index: 1;
        top: 3px;
        right: 3px;
        background-color: transparent;
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            fill: var(--title-color, $title-color);
        }
    }
}

.preset-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 16px;

    > button {
        display: inline-flex;
        align-items: center;
        background-color: transparent;
        padding: 4px 6px;
        color: inherit;
        border: 1px solid var(--main-text-color, $main-text-color);
        border-radius: 4px;
        transition: color 250ms border-color 250ms;

        &:hover {
            color: var(--title-color, $title-color);
            border-color: var(--title-color, $title-color);
        }
    }
}

</style>